const Testserver='65.20.74.205:8080'
const Localhost='localhost:8080'
const ClientServer='db-lawtech.in:8080'

// ------------------------------------LOgin--Satart--API------------------------------------//

  export const AdminLogin=`http://${ClientServer}/api/user/login`;

  export const QcLogin=`http://${ClientServer}/api/qc/login`;

  export const InternLogin=`http://${ClientServer}/api/intern/login`;

  export const forgotpassword=`http://${ClientServer}/api/user/sendOtpToMail?`;

  export const verifyopt=`http://${ClientServer}/api/user/verifyOtp?`;

  export const Confirmset=`http://${ClientServer}/api/user/forgotpassword?`;
  export const CheckSessionLogin=`http://${ClientServer}/api/user/checkSessionId/`


// ------------------------------------LOgin--End--API------------------------------------//






// ---------------------------------Admin- start-API------------------------------------------//
export const GetAllJudegentWokr=`http://${ClientServer}/api/workReport/getAllWorkDataForAdmin`;

export const GetQclist=`http://${ClientServer}/api/judgment/getAllmemberpageData`;

export const GetAllQcWorks=`http://${ClientServer}/api/judgment/getOverAllDataAssigningList`;

export const GeTAllMembers=`http://${ClientServer}/api/judgment/getDataForAssigningList`;

export const GetQcDropdown=`http://${ClientServer}/api/qc/getAllQc`;

export const GetInternListByQcId=`http://${ClientServer}/api/intern/getDataByQCId/`;

export const GetAllValume=`http://${ClientServer}/api/judgment/dropDownDataVolume?`;

export const Getvalumepartnumber=`http://${ClientServer}/api/judgment/dropDownDataPart?`;

export const CreateNewQc=`http://${ClientServer}/api/qc/register`;

export const AdminAllmemebarSearchApi=`http://${ClientServer}/api/judgment/searchMemberPageData?searchValue=`;

export const AdminQcsearch=`http://${ClientServer}/api/judgment/searchForAdmin?searchValue=`;

export const GetTotalCountforAssignJudgment=`http://${ClientServer}/api/judgment/getCountOfAssignedJudgements`;

export const GetTotalCountCAR=`http://${ClientServer}/api/workReport/getTotalCounts`;

export const GetAllTicketListformQcorIntern=`http://${ClientServer}/api/support/tickets/`;

export const ResolveTickets=`http://${ClientServer}/api/support/update/`;

export const GetInternReportByReportId=`http://${ClientServer}/api/workReport/getWorkReport/`;

export const SupportSearchticket=`http://${ClientServer}/api/support/searchAll?searchTerm=`;
export const GetDataonDateRange=`http://${ClientServer}/api/judgment/getFilteredDataForAdminDashBoard`;
export const AdminReviewSearch=`http://${ClientServer}/api/workReport/searchReviewReportsForAdmin?searchTerm=`;

// ---------------------------------Admin-End-API------------------------------------------//

// ----------------------------------Qc- start-API------------------------------------------//

export const GetInternLisbyQcId=`http://${ClientServer}/api/intern/allIntern/`;

export const GetValume=`http://${ClientServer}/api/judgment/dropDownDataVolume?`;

export const PartNumber=`http://${ClientServer}/api/judgment/dropDownDataPart?`;

export const GetworkReportIntern=`http://${ClientServer}/api/workReport/getreviewList/`;

export const CreateNewIntern=`http://${ClientServer}/api/intern/registerIntern/`;

export const GetInternListById=`http://${ClientServer}/api/judgment/getDataForAssigningListByqcId/`;

export const GetInternDropdown=`http://${ClientServer}/api/intern/allIntern/`;

export const SearchIntern=`http://${ClientServer}/api/judgment/searchByQcId/`;

export const GetTotalCountInternAssingJudegment=`http://${ClientServer}/api/judgment/getCountOfAssignedJudgementsByQcId/`;

export const GetalloverJudegmentnuber=`http://${ClientServer}/api/judgment/getCountOfAssignedJudgementsByQcId/`;

export const GetALloverCARQc=`http://${ClientServer}/api/workReport/getTotalCounts/`;
export const GetTicketlistPagenationQc=`http://${ClientServer}/api/support/tickets/`;
export const QcReviewListSearch=`http://${ClientServer}/api/workReport/searchReviewListByQcId/`;
// ----------------------------------Qc-End-API------------------------------------------//


// --------------------------------INTERN- start-API------------------------------------------//

export const PdfStartIndex = `http://${ClientServer}/api/judgment/fileSizeDatabase?`;

export const PdfFileSAssingJudgement = `http://${ClientServer}/api/judgment/fileSizeServer?`;

export const InternCreateJUdgement = `http://${ClientServer}/api/judgment/create/`;

export const QuestionSet = `http://${ClientServer}/api/workReport/updateReport/`;

export const GetJudgement = `http://${ClientServer}/api/judgment/`;

export const GetInterMyWork = `http://${ClientServer}/api/workReport/data/`;

export const GetUpdateworkfromaAdmin=`http://${ClientServer}/api/workReport/getWorkReport/`;

export const GetjudegmentPdf=`http://${ClientServer}/api/judgment/getJudgmentPdf?`;

export const GetjudegmentByInternId=`http://${ClientServer}/api/judgment/`;

export const GetTotalAssingJudegment=`http://${ClientServer}/api/judgment/getCountOfAssignedJudgementsByInternId/`;

export const GetInternCAR=`http://${ClientServer}/api/workReport/totalCountByInternId/`;

export const CreateSuportticekt=`http://${ClientServer}/api/support/raiseRequest`;

export const GetTicketList=`http://${ClientServer}/api/support/tickets/`;
export const GetTickeListPageNation=`http://${ClientServer}/api/support/tickets/`

// ------------------------------Intern-End-API------------------------------------------//




// ------------------------------Support Chat API------------------------------------------//
export const CreateSupportChat=`http://${ClientServer}/api/comments/addComment/`;
export const GetSupportChatAdmin=`http://${ClientServer}/api/comments/getComments/`;
export const GetTicketData=`http://${ClientServer}/api/support/getTicketById/`


// ------------------------------Support Chat API------------------------------------------//



// ------------------------------------Transfer Judgment INTERN TO INTERN--------------------------------------//

export const TransferJudgmentFromQCorIntern=`http://${ClientServer}/api/judgment/dropdown/`;

export const TransferJudgmentToQCorIntern=`http://${ClientServer}/api/judgment/reallocation?`
import React, { useEffect, useState } from 'react';
import QcSideBar from '../QcSideBar/QcSideBar';
import QCStyle from '../QCDashboard/QCDashboard.module.css'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
// import Dropdown1 from '../../../Assets/Images/Dropdown Icon.svg'
import QcProfile from '../../../Assets/Images/Profilephoto1.png'
import { GetALloverCARQc, GetDataonDateRange, GetInternListByQcId, GetTotalCountInternAssingJudegment } from '../../BaseApi/Baseapi';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DateRangePicker, SingleInputDateRangeField } from '@mui/x-date-pickers-pro';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Dropdown from 'react-bootstrap/Dropdown';
import Toaster from '../../Toaster/Toaster';




const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: 'white',

    padding: theme.spacing(1),
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: 90,
}));



function QcDashboard() {
    const [qcname, setQcname] = useState('');
    const [qcroll, setQcroll] = useState('');
    const [buttonstyle, setButtonstyle] = useState(1);
    const [totalcountassingjudegment, setTotalcountassingjudegment] = useState(0);
    const [qcpending, setQcpending] = useState(0);
    const [qcapproved, setQcapproved] = useState(0);
    const [qcRejected, setQcRejected] = useState(0);
    const [formattedDates, setFormattedDates] = useState({ startDate: '', endDate: '' });
    const [dateRange, setDateRange] = useState([null, null]);
    const [internlist, setInternList] = useState([]);
    const [selectintern, setSelectIntern] = useState('');
    const [internId, setInternId] = useState('');
    const [rangeData, setRangeData] = useState([]);
    const [errorcode,setErrorcode]=useState();

    useEffect(() => {
        setQcname(sessionStorage.getItem('QcName'));
        setQcroll(sessionStorage.getItem('QcRoll'))
    }, []);

    const GetTotalAssingCount = async () => {
        try {
            const ID = sessionStorage.getItem('QcID')
            const Data = await fetch(`${GetTotalCountInternAssingJudegment}${ID}`, {
                method: 'GET',
                headers: {
                    'content-type': "application/json"
                }

            })
            const Responce = await Data.json();
            setTotalcountassingjudegment(Responce)

        } catch (error) {

        }
    };





    const GetTotalCAR = async () => {
        try {
            const ID = sessionStorage.getItem('QcID')
            const Data = await fetch(`${GetALloverCARQc}${ID}`, {
                method: 'GET',
                headers: {
                    'content-type': "application/json"
                }

            })
            const Responce = await Data.json();
            setQcpending(Responce.completedCount);
            setQcapproved(Responce.approvedCount);
            setQcRejected(Responce.rejectedCount);

        } catch (error) {

        }
    };

    useEffect(() => {
        GetTotalAssingCount()
        GetTotalCAR()
    }, []);

    // select Date Range Function //
    const HandelselectDateRange = (newValue) => {
        if (newValue) {
            const [start, end] = newValue;

            setDateRange(newValue);

            const formattedStartDate = start ? dayjs(start).format('DD MMM YYYY') : '';
            const formattedEndDate = end ? dayjs(end).format('DD MMM YYYY') : '';

            setFormattedDates({ startDate: formattedStartDate, endDate: formattedEndDate });

        }
    };

    // Get Intern Dropdown List By Qc ID //
    const HandelInternList = async () => {
        try {
            const qcid = sessionStorage.getItem('QcID')
            const Data = await fetch(`${GetInternListByQcId}${qcid}`)
            var Responce = await Data.json();
            setInternList(Responce);

        } catch (error) {

        }
    };


    // Get Data for Intern By select date range//
    const HandelGetDataOnRangeIntern = async () => {
        try {
            const Data = await fetch(`${GetDataonDateRange}/${formattedDates.startDate}/${formattedDates.endDate}/intern/${internId}`, {
                method: "GET",
                headers: {
                    'content-type': 'application/json'
                }
            })
            const responce = await Data.json();
            setRangeData(responce);
            if(responce.errorCode===400){
                setErrorcode(true);
            }else{
                setErrorcode(false);
            }
        } catch (error) {
            // alert(error)
        }

    };

    useEffect(() => {
        if(internId){
            HandelGetDataOnRangeIntern();
        }
    }, [internId]);
    useEffect(() => {
            HandelGetDataOnRangeIntern();
    },[formattedDates]);

    return (
        <>
            <div className={QCStyle.MainDiv}>
                <div className={QCStyle.SideBar}>
                    <QcSideBar buttonstyle={buttonstyle} />

                </div>
                <div className={QCStyle.Dashboard}>
                    <div className={QCStyle.Div1}>
                        <Box sx={{ flexGrow: 1, }} >
                            <Grid container spacing={2} >
                                <Grid item xs={3}>
                                    <Item >
                                        <div className={QCStyle.BoxValue}>{totalcountassingjudegment}</div>
                                        <div className={QCStyle.BoxLabel}>Assigned Judgements</div>

                                    </Item>

                                </Grid>
                                <Grid item xs={3}>
                                    <Item>
                                        <div className={QCStyle.BoxValue}>{qcpending + qcRejected + qcapproved}</div>
                                        <div className={QCStyle.BoxLabel}>File Received</div>

                                    </Item>
                                </Grid>
                                <Grid item xs={3}>
                                    <Item>
                                        <div style={{ color: "#008000" }} className={QCStyle.BoxValue}>{qcapproved}</div>
                                        <div className={QCStyle.BoxLabel}>QC Approved</div>

                                    </Item>
                                </Grid>
                                <Grid item xs={3}>
                                    <Item>
                                        <div className={QCStyle.BoxValue}>{qcRejected}</div>
                                        <div className={QCStyle.BoxLabel}>QC Rejected</div>

                                    </Item>
                                </Grid>
                            </Grid>
                        </Box>
                    </div>

                    <div className={QCStyle.Div2}>
                        <div className={QCStyle.Div3} style={{ width: '40%', display: 'flex', justifyContent: 'space-between' }}>
                            <span style={{ fontSize: "11px" }}>This month: </span>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['SingleInputDateRangeField']}>
                                    <DateRangePicker

                                        slots={{ field: SingleInputDateRangeField }}
                                        name="allowedRange"
                                        value={dateRange}
                                        onChange={HandelselectDateRange}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                border: 'none',
                                                boxShadow: 'none',
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                border: 'none',
                                            },
                                            cursor: "pointer",
                                        }}
                                        slotProps={{
                                            textField: {
                                                placeholder: 'DD  MMM YYYY - DD MMM YYYY',

                                            },
                                        }}
                                    />
                                </DemoContainer>

                            </LocalizationProvider>
                        </div>
                        <div className={QCStyle.Div3} style={{ width: '40%' }} >
                            <div style={{ width: '100%  ', display: 'flex', justifyContent: 'space-between', }} >

                                <Dropdown onToggle={HandelInternList} style={{ width: "100%" }}>

                                    <Dropdown.Toggle
                                        className={QCStyle.Div2Box}
                                        style={{ width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'space-between', position: 'relative', }}
                                        variant="white"
                                        id="dropdown-basic"

                                    >

                                        <span style={{ fontSize: "12px" }} onClick={HandelInternList}>
                                            {selectintern ? selectintern : "Select Intern"}

                                        </span>

                                    </Dropdown.Toggle>
                                    <Dropdown.Menu
                                        className={QCStyle.dropdown}
                                        style={{ height: internlist.length > 5 ? "200px" : "fit-content", overflowY: 'scroll', }}
                                    >
                                        {internlist.map((item, index) => (
                                            <div key={index} style={{ position: 'relative', borderBottom: '1px solid #f5f5f5', }} onClick={() => {setSelectIntern(item.internName);setInternId(item.internId);HandelGetDataOnRangeIntern()}}>
                                                <Dropdown.Item





                                                >
                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%', gap: '10px', cursor: 'pointer' }} >
                                                        <div style={{ width: '25%', position: 'relative', display: 'flex', alignItems: 'center', flexDirection: 'row', left: '-10px', gap: "5px", padding: "10px", paddingTop: "10px" }}>
                                                            {/* <img
                                // src={Profile}
                                alt="profile"
                                style={{ height: '24px', borderRadius: '50%' }}
                            /> */}
                                                            <span>{item.internName}</span>


                                                        </div>



                                                    </div>





                                                </Dropdown.Item>




                                            </div>

                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    </div>

                    <div className={QCStyle.Div4} style={{ display: rangeData.length > 0 ? '' : 'none', }}>

                        <>
                            <div style={{
                                position: 'relative',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                display: rangeData.length > 0 ? 'flex' : 'none',
                            }}>
                                <div class='d-flex align-items-center gap-3'>
                                    <div>
                                        <img src={QcProfile} style={{ height: "24px", borderRadius: "50%" }}></img>
                                    </div>
                                    <div class='d-flex flex-column'>
                                        <span
                                            style={{
                                                position: 'relative',
                                                fontSize: '14px',
                                                fontFamily: 'Poppins',
                                                color: '#424242',
                                            }}>{selectintern}</span>
                                        

                                    </div>
                                </div>
                                <div>
                                   { errorcode==true?"":`${formattedDates.startDate}-${formattedDates.endDate}`}
                                </div>
                            </div>
                            {errorcode==true?'':rangeData?.map((item, index) => (
                                <div key={index}
                                    style={{
                                        position: 'relative',
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}>
                                    
                                    <div class='d-flex flex-column align-items-center'>
                                        <span
                                            style={{
                                                position: 'relative',
                                                Fontsize: '14px',
                                                fontweight: '500',
                                                fontFamily: 'Poppins',
                                                color: '#5c5c5c',
                                            }}>{item.judgementNo?item.judgementNo:0}</span>
                                        <span
                                            style={{
                                                fontsize: '12px',
                                                fontweight: '600',
                                                fontFamily: 'Poppins',
                                                color: '#a9a9a9',
                                            }}>No. of Judgement</span>
                                    </div>
                                   
                                    
                                    <div class='d-flex flex-column align-items-center'>
                                        <span
                                            style={{
                                                position: 'relative',
                                                Fontsize: '14px',
                                                fontweight: '500',
                                                fontFamily: 'Poppins',
                                                color: '#5c5c5c',
                                            }}>{item.qcRejected + item.qcApproved + item.fileReceived}</span>
                                        <span
                                            style={{
                                                fontsize: '12px',
                                                fontweight: '600',
                                                fontFamily: 'Poppins',
                                                color: '#a9a9a9',
                                            }}>File Received</span>
                                    </div>
                                    <div class='d-flex flex-column align-items-center'>
                                        <span
                                            style={{
                                                position: 'relative',
                                                Fontsize: '14px',
                                                fontweight: '500',
                                                fontFamily: 'Poppins',
                                                color: '#5c5c5c',
                                            }}>{item.fileReceived}</span>
                                        <span
                                            style={{
                                                fontsize: '12px',
                                                fontweight: '600',
                                                fontFamily: 'Poppins',
                                                color: '#a9a9a9',
                                            }}>Qc Pending</span>
                                    </div>
                                    <div class='d-flex flex-column align-items-center' style={{ backgroundColor: "#D8FFD1" }}>
                                        <span
                                            style={{
                                                position: 'relative',
                                                Fontsize: '14px',
                                                fontweight: '500',
                                                fontFamily: 'Poppins',
                                                color: '#5c5c5c',
                                            }}>{item.qcApproved}</span>
                                        <span
                                            style={{
                                                fontsize: '12px',
                                                fontweight: '600',
                                                fontFamily: 'Poppins',
                                                color: '#a9a9a9',
                                            }}>QC Approved</span>
                                    </div>
                                </div>
                            ))}

                        </>
                    </div>
                </div>
            </div >

            <Toaster errorcode={errorcode}/>
        </>
    )
};

export default QcDashboard;